<template>
    <div>
        <el-form :inline="true" :model="queryForm" ref="queryForm" size="small" style="margin-bottom: 20px">
            <el-form-item prop="username">
                <el-input v-model="queryForm.keyWord" placeholder="读者卡号、姓名、身份证号" clearable style="width:300px" />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="querySubmit">查询</el-button>
            </el-form-item>
            <el-form-item style="position: absolute; right: 15px;">
                <el-button icon="el-icon-time" @click="openCondition">筛选</el-button>
                <el-button  icon="el-icon-notebook-2" @click="excel.excelDialogVisible = true">导出</el-button>
                <el-button v-if="getRole == 'admin'" icon="el-icon-plus" type="warning" @click="addNewReader">新建读者</el-button>
                <ImportExcel :action="action" v-if="getRole == 'admin'"></ImportExcel>
            </el-form-item>
        </el-form>
        
         <!-- 导出提示框 -->
         <div v-if="excel.excelDialogVisible">
          <el-dialog
            title="读者导出"
            :visible.sync="excel.excelDialogVisible"
            width="45%"
            :before-close="ExcelHandleClose"
            append-to-body>
            <template>
              <el-radio-group v-model="excel.excelRadio" size="mini" >
                <div >
                  <el-radio label="10"  v-if="multipleSelection.length>0">当前勾选的{{ multipleSelection.length }}条数据</el-radio>
                </div>
                <div >
                  <el-radio label="11" v-if="queryForm.keyWord != '' || readerSiftDTOList.length >0">当前筛选的 {{ this.queryForm.total }}条数据</el-radio>
                </div>
                <div>
                  <el-radio label="22">导出全部的{{ this.excel.excelTotalNumber }}条数据</el-radio>
                </div>
              </el-radio-group>
             
            </template>

            <span slot="footer" class="dialog-footer">
              <el-button @click="excel.excelDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="excel.excelDialogVisible = false ,readerExcel()">确 定</el-button>
            </span>
          </el-dialog>
        </div>

        <!-- 点击添加筛选后增加的   -->
        <div class="middle" v-if="openStatus != false && readerSiftDTOList.length != 0"
            v-for="(item, index) in readerSiftDTOList">
            <div style="display: flex; align-items: center;margin-right: 5px;">且</div>
            <el-select class="middle-item" v-model="item.field" placeholder="请选择" @change="clearContent(index,item)">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"  >
                </el-option>
            </el-select>

            <!-- 卡号、在借册数的时候 选择大于小于 -->
            <el-select
                v-if="readerSiftDTOList[index].field == 1 || readerSiftDTOList[index].field == 2 || readerSiftDTOList[index].field == 3 || readerSiftDTOList[index].field == 4 || readerSiftDTOList[index].field == 10 || readerSiftDTOList[index].field == 11 || readerSiftDTOList[index].field == 14"
                class="middle-item" v-model="item.condition" placeholder="请选择"
                :disabled="readerSiftDTOList[index].field == ''">
                <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>

            <!-- v-if="readerSiftDTOList[index].field != 1 || readerSiftDTOList[index].field != 2"  -->
            <el-select
                v-if="readerSiftDTOList[index].field != 1 && readerSiftDTOList[index].field != 2 && readerSiftDTOList[index].field != 3 && readerSiftDTOList[index].field != 4 && readerSiftDTOList[index].field != 10 && readerSiftDTOList[index].field != 11 && readerSiftDTOList[index].field != 14"
                class="middle-item" v-model="item.condition" placeholder="请选择"
                :disabled="readerSiftDTOList[index].field == ''">
                <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>

            <!-- 如果是性别 包含 选项框 -->
            <el-select v-if="readerSiftDTOList[index].field == 5 && readerSiftDTOList[index].condition == 1" class="middle-item"
                v-model="item.content" placeholder="请选择" :disabled="readerSiftDTOList[index].field == ''">
                <el-option v-for="item in optionsSex" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>

            <!-- 如果是借阅规则 包含 选项框 -->
            <el-select v-if="readerSiftDTOList[index].field == 6 && readerSiftDTOList[index].condition == 1" class="middle-item"
                v-model="item.content" placeholder="请选择" :disabled="readerSiftDTOList[index].field == ''">
                <el-option v-for="item in optionsLendRule" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>

            <!-- 如果是年级 包含 选项框 -->
            <el-select v-if="readerSiftDTOList[index].field == 8 && readerSiftDTOList[index].condition == 1" class="middle-item"
                v-model="item.content" placeholder="请选择" :disabled="readerSiftDTOList[index].field == ''">
                <el-option v-for="item in optionsGrade" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>

            <!-- 如果是民族 包含 选项框 -->
            <el-select v-if="readerSiftDTOList[index].field == 13 && readerSiftDTOList[index].condition == 1"
                class="middle-item" v-model="item.content" placeholder="请选择" :disabled="readerSiftDTOList[index].field == ''">
                <el-option v-for="item in optionsNation" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>

            <!-- 如果是所在分馆，包含 选择框 -->
            <el-select v-if="readerSiftDTOList[index].field == 15 && readerSiftDTOList[index].condition == 1"
                class="middle-item" v-model="item.content" placeholder="请选择" :disabled="readerSiftDTOList[index].field == ''">
                <el-option v-for="item in allSubMsg" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>

            <!-- 如果是过期时间 -->
            <el-date-picker v-if="readerSiftDTOList[index].field == 2 && readerSiftDTOList[index].condition == 4"
                v-model="item.content" type="date" 
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd" 
                placeholder="选择日期-过期时间1">
            </el-date-picker>
            <!-- 横杠线 -->
            <div v-if="readerSiftDTOList[index].condition == 4 && readerSiftDTOList[index].field == 2"
                style="display: flex;align-items: center;">~</div>
            <!-- 如果是过期时间  介于 两个时间-->
            <el-date-picker v-if="readerSiftDTOList[index].condition == 4 && readerSiftDTOList[index].field == 2"
                v-model="item.betweenContent" type="date" 
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                placeholder="选择日期-过期时间2">
            </el-date-picker>

            <!-- 如果是冻结时间 -->
            <el-date-picker v-if="readerSiftDTOList[index].field == 3 && readerSiftDTOList[index].condition == 4"
                v-model="item.content" type="date" 
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                placeholder="选择日期-冻结时间1">
            </el-date-picker>
            <!-- 横杠线 -->
            <div v-if="readerSiftDTOList[index].condition == 4 && readerSiftDTOList[index].field == 3"
                style="display: flex;align-items: center;">~</div>
            <!-- 如果是冻结冻时间  介于 两个时间-->
            <el-date-picker v-if="readerSiftDTOList[index].condition == 4 && readerSiftDTOList[index].field == 3"
                v-model="item.betweenContent" type="date" 
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                placeholder="选择日期-冻结时间2">
            </el-date-picker>

            <!-- 如果是解冻时间 -->
            <el-date-picker v-if="readerSiftDTOList[index].field == 4 && readerSiftDTOList[index].condition == 4"
                v-model="item.content" type="date" 
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                placeholder="选择日期-解冻时间1">
            </el-date-picker>
            <!-- 横杠线 -->
            <div v-if="readerSiftDTOList[index].condition == 4 && readerSiftDTOList[index].field == 4"
                style="display: flex;align-items: center;">~</div>
            <!-- 如果是解冻时间  介于 两个时间-->
            <el-date-picker v-if="readerSiftDTOList[index].condition == 4 && readerSiftDTOList[index].field == 4"
                v-model="item.betweenContent" type="date" 
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                placeholder="选择日期-解冻时间2">
            </el-date-picker>

            <!-- 如果是录入时间 -->
            <el-date-picker v-if="readerSiftDTOList[index].field == 14 && readerSiftDTOList[index].condition == 4"
                v-model="item.content" type="date" 
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                placeholder="选择日期-录入时间1">
            </el-date-picker>
            <!-- 横杠线 -->
            <div v-if="readerSiftDTOList[index].condition == 4 && readerSiftDTOList[index].field == 14"
                style="display: flex;align-items: center;">~</div>
            <!-- 如果是录入时间  介于 两个时间-->
            <el-date-picker v-if="readerSiftDTOList[index].condition == 4 && readerSiftDTOList[index].field == 14"
                v-model="item.betweenContent" type="date" 
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                placeholder="选择日期-录入时间2">
            </el-date-picker>

            <el-date-picker
                v-if="readerSiftDTOList[index].field != 1 && readerSiftDTOList[index].field != 5 && readerSiftDTOList[index].field != 6 && readerSiftDTOList[index].field != 7 && readerSiftDTOList[index].field != 8 && readerSiftDTOList[index].field != 9 && readerSiftDTOList[index].field != 10 && readerSiftDTOList[index].field != 11 && readerSiftDTOList[index].field != 12 && readerSiftDTOList[index].field != 13 && readerSiftDTOList[index].field != 15 && readerSiftDTOList[index].condition != 4"
                v-model="item.content" type="date" 
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                placeholder="选择日期14" >
            </el-date-picker>
            <!-- || readerSiftDTOList[index].condition == 2 || readerSiftDTOList[index].condition == 3 -->
            <el-input
                v-if="readerSiftDTOList[index].field != 2 && readerSiftDTOList[index].field != 3 && readerSiftDTOList[index].field != 4 && readerSiftDTOList[index].field != 5 && readerSiftDTOList[index].field != 6 && readerSiftDTOList[index].field != 8 && readerSiftDTOList[index].field != 13 && readerSiftDTOList[index].field != 14 && readerSiftDTOList[index].field != 15" 
                class="middle-item" v-model="item.content" placeholder="请输入内容"
                :disabled="readerSiftDTOList[index].condition == '' || (readerSiftDTOList[index].field == 7 && readerSiftDTOList[index].condition !=1)"  
                style="width: 40%;">
            </el-input>
            <!-- 横杠线 -->
            <div v-if="readerSiftDTOList[index].field != 2 && readerSiftDTOList[index].condition == 4 && readerSiftDTOList[index].field != 3 && readerSiftDTOList[index].field != 4 && readerSiftDTOList[index].field != 14"
                style="display: flex;align-items: center;margin: 0 10px;">~</div>
            <el-input
                v-if="readerSiftDTOList[index].field != 2 && readerSiftDTOList[index].condition == 4 && readerSiftDTOList[index].field != 3 && readerSiftDTOList[index].field != 4 && readerSiftDTOList[index].field != 14 "
                class="middle-item" v-model="item.betweenContent" placeholder="请输入内容"
                :disabled="readerSiftDTOList[index].condition == '' && readerSiftDTOList[index].condition == '2' && readerSiftDTOList[index].condition == '3'"
                style="width: 40%;">
            </el-input>
            <div class="circle" @click="delCondition">
                _<div class="circle-item"></div>
            </div>
        </div>

        <div class="bottomBak" v-if="openStatus != false">
            <div class="txtBak" @click="addCondition">+添加筛选条件</div>
            <div class="right">
                <el-button size="small" style="height: 35px;" @click="clearCondition">清空条件</el-button>
                <el-button type="primary" size="small" style="height: 35px;" @click="startCondition() ;clearBetween()"  >开始筛选</el-button>
            </div>
        </div>

        <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
            <el-tab-pane label="正常" name="first"  ></el-tab-pane>
            <el-tab-pane label="冻结" name="second"></el-tab-pane>
            <el-tab-pane label="全部" name="third"></el-tab-pane>
            <el-table :data="readerList" style="width: 100%" border :header-cell-style="rowClass" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" align="center">
                </el-table-column>
                <el-table-column prop="username" label="姓名" width="180" align="center">
                </el-table-column>
                <!-- ！！！！ -->
                <el-table-column prop="branchName" label="所属分馆" width="180" align="center">
                </el-table-column>
                <el-table-column prop="cardNo" label="卡号" width="180" align="center">
                </el-table-column>
                <el-table-column prop="phone" label="手机号" align="center">
                </el-table-column>
                <!-- ！！！！ -->
                <el-table-column prop="ruleName" label="使用借阅规则" align="center">
                </el-table-column>
                <el-table-column prop="is_frozen" label="读者状态" align="center">
                </el-table-column>
                <el-table-column prop="school" label="学校" align="center">
                </el-table-column>
                <el-table-column prop="contactAddress" label="地址" align="center">
                </el-table-column>
                <el-table-column prop="grade" label="年级" align="center">
                </el-table-column>
                <el-table-column prop="clazz" label="班次" align="center">
                </el-table-column>
                <!-- ！！！！！ -->
                <el-table-column prop="borrowNum" label="在借册数" align="center">
                </el-table-column>
                <el-table-column prop="allBorrowNum" label="累计借阅图书" align="center">
                </el-table-column>
                <!-- ！！！！！ -->
                <el-table-column prop="expireDate" label="过期时间" align="center">
                </el-table-column>
                <el-table-column prop="studentId" label="学号" align="center">
                </el-table-column>
                <el-table-column prop="sex" label="性别" align="center">
                </el-table-column>
                <el-table-column prop="contactName" label="紧急联系人" align="center">
                </el-table-column>
                <el-table-column prop="contactPhone" label="紧急联系人电话" align="center">
                </el-table-column>
                <el-table-column prop="createTime" label="录入时间 " align="center">
                </el-table-column>
               
                <el-table-column prop="is_frozen" label="状态 " align="center">
                </el-table-column>
                <el-table-column prop="method" label="操作" align="center" fixed="right" >
                    <template slot-scope="scope">
                        <div style="color: #2d8cf0;cursor: pointer;" @click="forzenReader(scope.row.cardNo,scope.row.is_frozen)">{{scope.row.is_frozen=='正常'? '冻结' : '解冻'}}</div>
                    </template>
                </el-table-column>
            </el-table>
        </el-tabs>
        <!-- 分页 -->
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryForm.current" :page-size="queryForm.size" :page-sizes="[2, 5, 10, 20, 100]" :page-count="queryForm.page"
            layout="total, sizes, prev, pager, next, jumper" :total="queryForm.total">
        </el-pagination>
    </div>
</template>

<script>
import { listReader,freezeReader ,readerFindOne, exportReader} from '@/api/reader'
import { listLibrary } from '@/api/library'
import { listBook } from '@/api/book'
import ImportExcel from '../../components/ImportExcel.vue';
import {  getRole } from '@/utils/auth'

export default {
  components: { ImportExcel },
    data() {
        return {
            //通过 getRole 来控制当前页面的显示也隐藏
            getRole:"",
            //控制导出弹框显示的
            excel:{
                excelDialogVisible: false,
                excelRadio: '',
                excelTotalNumber: undefined,
                startConditionSelect: []
            },
            //当前上传图片的url地址
            action:"https://api.leshop.cloud/reader/import",
            // 查询表单数据
            queryForm: {
                type: 'book',
                keyWord: '',
                isFrozen: "0",
                type: "cardNo",
                current: 1,
                page: 1,
                size: 10,
                total: undefined
            },

            // =================筛选=============
            openStatus: false,
            // 筛选的条件
            conditionList: [{
                field: "",
                condition: "",
                content: "",
                // 当选择总借阅次数的时候才会启用！
                betweenContent: "",
            }],
            readerSiftDTOList: [],
            options: [{
                label: '卡号',
                disabled: false,
                value: 1,
            }, {
                label: '过期时间',
                disabled: false,
                value: 2,
            }, {
                label: '冻结时间',
                disabled: false,
                value: 3,
            }, {
                label: '解冻时间',
                disabled: false,
                value: 4,
            }, {
                label: '性别',
                disabled: false,
                value: 5,
            }, {
                label: '借阅规则',
                disabled: false,
                value: 6,
            }, {
                label: '学校',
                disabled: false,
                value: 7,
            }, {
                label: '年级',
                disabled: false,
                value: 8,
            }, {
                label: '班次',
                disabled: false,
                value: 9,
            }, {
                label: '在借册数',
                disabled: false,
                value: 10,
            }, {
                label: '累计借阅册数',
                disabled: false,
                value: 11,
            }, {
                label: '备注',
                disabled: false,
                value: 12,
            }, {
                label: '民族',
                disabled: false,
                value: 13,
            }, {
                label: '录入时间',
                disabled: false,
                value: 14,
            }, {
                label: '所属分馆',
                disabled: false,
                value: 15,
            }],
            // 包含 为空
            options1: [{
                label: '包含',
                value: 1
            }, {
                label: '为空',
                value: 2
            }, {
                label: '不为空',
                value: 3
            }],
            // 按读者搜
            options2: [{
                value: 'reader',
                label: '按读者搜'
            }, {
                value: 'book',
                label: '按图书搜'
            }],
            // 大于小于等于
            options3: [{
                value: 1,
                label: "等于"
            }, {
                value: 2,
                label: "大于等于"
            }, {
                value: 3,
                label: "小于等于"
            }, {
                value: 4,
                label: "介于"
            }],
            // 性别选项
            optionsSex: [{
                value: 1,
                label: "男"
            }, {
                value: 2,
                label: "女"
            }, {
                value: 3,
                label: "其他"
            }],
            // 借阅规则选项
            optionsLendRule: [],
            // 年级选项
            optionsGrade: [{
                value: 1,
                label: "小班"
            }, {
                value: 2,
                label: "中班"
            }, {
                value: 3,
                label: "大班"
            }, {
                value: 4,
                label: "一年级"
            }, {
                value: 5,
                label: "二年级"
            }, {
                value: 6,
                label: "三年级"
            }, {
                value: 7,
                label: "四年级"
            }, {
                value: 8,
                label: "五年级"
            }, {
                value: 9,
                label: "六年级"
            }, {
                value: 10,
                label: "七年级"
            }, {
                value: 11,
                label: "八年级"
            }, {
                value: 12,
                label: "九年级"
            }, {
                value: 13,
                label: "高一"
            }, {
                value: 14,
                label: "高二"
            }, {
                value: 15,
                label: "高三"
            }, {
                value: 16,
                label: "毕业"
            }, {
                value: 17,
                label: "其他"
            }],
            // 民族选项
            optionsNation: [{
                value: 1,
                label: "汉族"
            }],
            // 图书位置选项
            optionsBookPosition: [],
            // 所有分馆信息选项
            allSubMsg: [],
            // 能否外借选项
            canBorrowOp: [{
                value: 0,
                label: "可外借"
            }, {
                value: 1,
                label: "不可外借"
            }],

            readerList: [],

            activeName: 'first',

            // 当前的状态码  0 正常   1冻结   3 全部  
            isStatus:0 ,

            // 多选框数据
            multipleSelection: []
        };
    },
    created() {

         this.getReaderList();
        //this.getAllSubMsg();
        this.getRole = getRole()
    },
    updated(){
    //console.log("我执行了")
    this.getTotalNum()
   // console.log(this.excel.startConditionSelect)

  },
    methods: {
        //表格 选择发生变化
        handleSelectionChange(val){
            // console.log(val)
            this.multipleSelection = val;

        },
         //获取当前的全部数据
        getTotalNum(){
            const queryParams = { page: this.queryForm.current, size: this.queryForm.size, type:this.isStatus }
            listBook(queryParams).then(({ data }) => {
            this.excel.excelTotalNumber = data.total
            })
        },
        ExcelHandleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
        },
        //读者导出列表
           readerExcel(){
            let ids=[] 
            if(this.excel.excelRadio == 10){
                this.multipleSelection.forEach(w => {
                    console.log(w.id)
                    ids.push(JSON.parse(w.id))
            });
            }else if(this.excel.excelRadio == 11){
            console.log(this.excel.startConditionSelect)
            ids = this.excel.startConditionSelect 
            }else{

            }
            exportReader(ids).then(
                 (res)=>{
                    
                    const str = res.headers['content-disposition'].split(';')[1].split('=')[1];
                    const filename = decodeURIComponent(str);

                   // 用blob存储二进制文件
                    const blob = new Blob([res.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8', //   application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8 设置文件类型 pdf是application/pdf，word是application/vnd.openxmlformats-officedocument.wordprocessingml.document，其他类型可以百度一下
                    });
                    // 创建一个临时的url指向blob对象
                    const objectUrl = window.URL.createObjectURL(blob);
                    // 创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
                    const a1 = document.createElement('a');
                    a1.setAttribute('href', objectUrl);
                    a1.setAttribute('download', filename);
                    document.body.appendChild(a1);
                    a1.click();
                    // 5.释放这个临时的对象url
                    document.body.removeChild(a1);
                    window.URL.revokeObjectURL(objectUrl);
                }
            );

         
        },

        readerExcel11(){
            let data =[]
            this.multipleSelection.forEach(w => {
                    data.push(JSON.parse(w.id))
                });

            let xhr = new XMLHttpRequest();
            xhr.open("POST", 'https://api.leshop.cloud/reader/export');
			xhr.responseType = 'blob';
			xhr.setRequestHeader('Content-Type','application/json');
            xhr.setRequestHeader('Authorization','eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyb2xlIjoiYWRtaW4iLCJiaWQiOjEsImFpZCI6MSwiYWNjb3VudCI6ImxldHUxMjM0IiwidXNlcm5hbWUiOiLnqIvmoLnno4ogICJ9.AkDHUs_6NXASjfwNqc1G9pB-eCO5ZFiSoSQK_5WBLeU');
        
        
				xhr.onload = function (){
					if(this.status==200){
						let blob = this.response;
                       
						if(blob && blob.size > 0){
             
							let fileName = "读者数据表.xls";
							// 创建一个临时链接并模拟点击进行下载
							let a = document.createElement('a');
							a.href = window.URL.createObjectURL(blob);
							a.download = fileName;
							a.click();
						} else {
							console.error("下载失败");
						}
				    }
				}
	                xhr.send(JSON.stringify(data)); 
                },
        
        //清空 between里面的内容
        clearContent(index,item){
            console.log(index)
            console.log(this.readerSiftDTOList[index])
            this.readerSiftDTOList[index].content=""
            this.readerSiftDTOList[index].betweenContent=""
            //this.lendRecordSiftDTOList[index].condition=""

            // this.readerSiftDTOList[index]={
            //     "betweenContent": "",
            //      "condition": item.condition,
            //      "content": "",
            //      "field":item.betweenContent
            // }
                //if(item.field)
                
            
            // this.readerSiftDTOList=[]
            // this.readerSiftDTOList.push({
            //     "betweenContent": "",
            //     "condition": 0,
            //     "content": "",
            //     "field": 0
            // })
        },
        clearBetween(){
           

            this.readerSiftDTOList.forEach((item)=>{
                if (item.condition != 4) {
                    item.betweenContent=""
                }
            })
        },
        // 冻结
        forzenReader(item,status) {
            console.log(item+"==========="+status);
            if(status=="正常"){
                console.log("我是冻结")
                let queryParams = {cardNo:item,type:1}
                freezeReader(queryParams).then(res=>{
                console.log(res);
                this.getReaderList(this.isStatus)
            })
            }else{
                console.log("我是解冻")
                let queryParams = {cardNo:item,type:0}
                freezeReader(queryParams).then(res=>{
                console.log(res);
                this.getReaderList(this.isStatus)
            })
            }
            
        }, 
        //新增加读者
        addNewReader() {
            this.$router.push('/reader/addReader')
        },


        // // 获得所有分馆信息
        // getAllSubMsg() {
        //     listLibrary().then(({ data }) => {
        //         for (let i = 0; i < data.libraryBranchData.length; i++) {
        //             this.allSubMsg.push({ value: i, label: data.libraryBranchData[i].branchName })
        //         }
        //     })
        // },

        //============== 查询表单 =================
        // 查询表单提交
        querySubmit() {
            console.log(this.queryForm)
            let data = { readerSiftDTOList: this.readerSiftDTOList, keyWord: this.queryForm.keyWord, isFrozen:this.isStatus , page:1 , size:this.queryForm.size }

            listReader(data).then(({data}) =>{
                    // if(data.pageNum == 0){
                    //     this.queryForm.page = 1
                    // }
                    this.readerList = data.readerData
                    this.queryForm.page = data.pageNum;
                    this.queryForm.current = data.current;
                    this.queryForm.total = data.total;
                    this.excel.startConditionSelect = []
                        data.readerData.forEach((item)=>{
                        //console.log(item.book.id)
                        this.excel.startConditionSelect.push(item.id)
                    })
            })
            // listReader
        },
        // 开启筛选条件
        openCondition() {
            this.openStatus = !this.openStatus;
        },
        // 获取读者列表
        getReaderList(type) {
            // 	冻结状态：0未冻结,1已冻结,2全部
            let data = { readerSiftDTOList: this.readerSiftDTOList, keyWord: this.queryForm.keyWord, isFrozen:type==null?this.isStatus:type  , page:1 , size:this.queryForm.size }
           console.log(data)
            listReader(data).then(({ data }) => {
               
                data.readerData.forEach((item)=>{
                //console.log(item.book.id)
                this.excel.startConditionSelect.push(item.id)
                });
                this.readerList = data.readerData;
                this.queryForm.page = data.pageNum;
                this.queryForm.current = data.current;
                this.queryForm.total = data.total;
            })


            // if (this.activeName == 'first') {
            //     this.queryForm.isFrozen = type;
            //     listReader({ ...this.queryForm }).then(({ data }) => {
            //         this.readerList = data.readerData;
            //         this.readerList = this.readerList.map(item => {
            //             return { ...item, method: "冻结" }
            //         })
            //         this.queryForm.page = data.pageNum;
            //         this.queryForm.current = data.current;
            //         this.queryForm.total = data.total;
            //     })
            // }
            // if (this.activeName == 'second') {
            //     this.queryForm.isFrozen = type+"";
            //     listReader({ ...this.queryForm }).then(({ data }) => {
            //         this.readerList = data.readerData;
            //         this.readerList = this.readerList.map(item => {
            //             return { ...item, method: "解冻" }
            //         })
            //         this.queryForm.page = data.pageNum;
            //         this.queryForm.current = data.current;
            //         this.queryForm.total = data.total;
            //     })
            // }
            // if (this.activeName == 'third') {
            //     this.queryForm.isFrozen = type;
            //     listReader({ ...this.queryForm }).then(({ data }) => {
            //         this.readerList = data.readerData;
            //         this.readerList = this.readerList.map(item => {
            //             return { ...item, method: "冻结" }
            //         })
            //         this.queryForm.page = data.pageNum;
            //         this.queryForm.current = data.current;
            //         this.queryForm.total = data.total;
            //     })
            // }

        },

        //=============== 分页 ==================
        // 每页显示数改变
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.queryForm.size = val
            let data = { readerSiftDTOList: this.readerSiftDTOList, keyWord: this.queryForm.keyWord, isFrozen:this.isStatus , page:this.queryForm.current , size:this.queryForm.size }
           console.log(data)
            listReader(data).then(({ data }) => {
                this.readerList = data.readerData;
                this.queryForm.page = data.pageNum;
                this.queryForm.current = data.current;
                this.queryForm.total = data.total;
            })
        },
        // 当前页改变 
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`)
            this.queryForm.current = val
            let data = { readerSiftDTOList: this.readerSiftDTOList, keyWord: this.queryForm.keyWord, isFrozen:this.isStatus , page:this.queryForm.current , size:this.queryForm.size }
           console.log(data)
            listReader(data).then(({ data }) => {
                this.readerList = data.readerData;
                this.queryForm.page = data.pageNum;
                this.queryForm.current = data.current;
                this.queryForm.total = data.total;
            })
        },

        // ==================筛选=================
        // 开启筛选条件
        openCondition() {
            this.openStatus = !this.openStatus;
        },
        // 添加条件
        addCondition() {
            this.readerSiftDTOList.push({
                "betweenContent": "",
                "condition": 0,
                "content": "",
                "field": 0
            })
            console.log(this.readerSiftDTOList)
        },
        // 删除条件
        delCondition() {
            this.readerSiftDTOList.pop()
            console.log("删除一个", this.conditionList);
        },
        // 清空条件
        clearCondition() {
            this.conditionList = [{
                "betweenContent": "",
                "condition": 0,
                "content": "",
                "field": 0
            }];
            this.readerSiftDTOList = [];
        },
        // 开始筛选  多条件筛选！！！
        startCondition() {
            let data = { readerSiftDTOList: this.readerSiftDTOList, type: 0 }
            console.log(data);
            listBook(data).then(({ data }) => {
                // console.log(data);
                this.tableData = data.bookData;
                this.excel.startConditionSelect = []
                data.readerData.forEach((item)=>{
                //console.log(item.book.id)
                this.excel.startConditionSelect.push(item.id)
                })
            })
        },

        handleClick(tab, event) {
            this.activeName = tab.name;
            console.log(tab.name)
            let type = 0;
            if (this.activeName == 'first') { type = 0;this.getReaderList(type);this.isStatus=0 ;}
            if (this.activeName == 'second') { type = 1; this.getReaderList(type);this.isStatus=1 ;}
            if (this.activeName == 'third') { type = 2; this.getReaderList(type) ;this.isStatus=2;}
            
        },
        // 表格居中
        rowClass() {
            return "text-align:center";
        }
    },
};
</script>

<style >
.middle {
    display: flex;
    margin-top: 15px;
}

.middle .middle-item {
    margin-left: 10px;
}

.middle .circle {
    font-size: 20px;
    color: rgb(237, 64, 20);
    position: relative;
    margin-left: 10px;
}

.middle .circle-item {
    position: absolute;
    border: 1px solid rgb(237, 64, 20);
    border-radius: 50%;
    height: 14px;
    width: 14px;
    top: 14px;
    right: -2.5px;
    cursor: pointer;
}

.bottomBak {
    display: flex;
    margin-top: 15px;
}

.bottomBak .txtBak {
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #DCDFE6;
    color: #2d8cf0;
    cursor: pointer;
    line-height: 1;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-right: 40px;
    margin-bottom: 6px;
}

.bottomBak .right {
    display: flex;
}
</style>