import request from '@/utils/request'

// 查询读者列表
export function listReader(data) {
  return request({
    url: '/reader/findAll', 
    method: 'post',
    data
  })
}

// 添加读者
export function addReader(data) {
  return request({
    url: '/reader/add',
    method: 'post',
    data
  })
}

// 冻结读者
export function freezeReader(data) {
  return request({
    url: `/reader/frozenCardNo?type=${data.type}`,
    method: 'post',
    data:[JSON.parse(data.cardNo)]
  })
}

// 删除读者
export function deleteReader(data) {
  return request({
    url: '/reader/delete',
    method: 'post',
    data
  })
}

// 重置密码
export function resetPwd(params) {
  return request({
    url: '/reader/resetPwd',
    method: 'post',
    params
  })
}

// 读者画像
export function readerFigure(data){
  return request({
    url:'/reader/figure',
    method:'get',
    data
  })
}

// 单个查询读者  
export function readerFindOne(data){
  return request({
    url:'/reader/findOne',
    method:'get',
    params:{
      cardNo:data
    }
  })
}

//导出读者
export function exportReader(data){
  return request({
    url:'/reader/export',
    method: 'post',
    
    responseType: 'blob',
    
    data,
   
    
  })
}